<template>
  <div>
    <div class="header">
      <b-button @click="addAction()">Add</b-button>
    </div>
    <div class="table">
      <b-table :items="items" :fields="fields"  responsive >
         <template #cell(fullName)="data">
            {{data.item.fullName ? data.item.fullName  : '-'}}
        </template>
        <template #cell(mobile)="data">
            {{data.item.mobile ? data.item.mobile  : '-'}}
        </template>
         <template #cell(email)="data">
            {{data.item.email ? data.item.email  : '-'}}
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <b-button variant="info" size="sm"  class="ml-1" @click="editAction(data.item)">Edit</b-button>
            <b-button variant="danger" size="sm"  class="ml-1" @click="deleteRow(data.item.id)">Delete</b-button>
          </div>
        </template>
      </b-table>
    </div>

       <b-modal ref="myModal" hide-backdrop ok-only no-close-on-backdrop no-close-on-esc hide-footer size="lg" @close="onCloseMyModal">
        <template #modal-title>
          <div class="d-block text-center">
            <h3 v-if="typeModal.add == true">Add User</h3>
            <h3 v-else-if="typeModal.edit == true">Edit User</h3>
         </div>
       </template>

       <div class="body">
         <b-form @submit="onSubmit">
          <div class="row">
          </div>
          <div class="row">
            <div class="col-3">
              <label for="text">UserName</label>
              <b-form-input  placeholder="userA" maxlength="100" required v-model="inputVal.userName" :disabled="typeModal.edit || typeModal.view"></b-form-input>
            </div>
            <div class="col-5">
              <label for="text">Password</label>
              <div class="d-flex">
                <b-form-input  id="testing-code" type="text"  required  v-model="inputVal.password" v-if="typeModal.add"></b-form-input>
                <b-form-input  id="testing-code" type="text"    v-model="inputVal.newPassWord" v-if="typeModal.edit"></b-form-input>
                <div class="copy d-flex justify-content-center">
                  <span class="align-self-center" size="sm" @click="copyPassword">copy</span>
                </div>
              </div>
                <b-button size="sm" @click="generatePassword">Generate</b-button>
                <div class="text-warning">- copy รหัสผ่านเก็บไว้เพราะจะดูไม่ได้อีก</div>
                <div class="text-danger">- ไม่สามารถดูรหัสเก่าได้ แต่แก้ไขแล้วอัพเดตรหัสใหม่ได้</div> 
            </div>
            <div class="col-4">
              <label for="text">Email</label>
              <b-form-input  placeholder="wasan.ong@mail.com" type="email" v-model="inputVal.email"></b-form-input>
            </div>
          </div>
          <div class="row row-mt">
              <div class="col-4">
              <label for="text">Mobile</label>
              <b-form-input  placeholder="0879650025"   maxlength="10" v-model="inputVal.mobile"></b-form-input>
            </div>
          <div class="col-4">
              <label for="text">FullName</label>
              <b-form-input  placeholder="วสันต์ ก่อนลา" maxlength="50" v-model="inputVal.fullName" ></b-form-input>
            </div>
            <div class="col-4">
              <label for="text">Role</label>
              <b-form-select required v-model="roleSearch.selected" :options="roleSearch.options"  />
            </div>
          </div>
             <div class="footer d-flex justify-content-center mt-5">
              <b-button type="submit" variant="primary" v-show="typeModal.view != true">Save</b-button>
            </div>
          </b-form>
        </div>
    </b-modal>

    <b-modal ref="deleteModal" hide-backdrop ok-only no-close-on-backdrop no-close-on-esc hide-footer size="sm"  @close="onCloseDeleteModal">
        <template #modal-title>
          <div class="d-block text-center">
            <h3>Delete User</h3>
         </div>
       </template>
       <div class="body">
          <b-form @submit="onSubmitDelete">
            <div class="row row-mt">
              <div class="col-8">
                <label for="text-password">Please input password for confirm.</label>
                <b-form-input type="password" v-model="inputDeleteVal.password" @keydown="handleKeyDown"  aria-describedby="password-help-block" required></b-form-input>
                <b-form-text id="password-help-block" />
              </div>
              <div class="col-4 d-flex align-items-end">
                <b-button type="submit" variant="primary">Confirm</b-button>
              </div>
            </div>
          </b-form>
       </div>
       </b-modal>
  </div>
</template>


<script>
import { BButton, BTable, BFormInput, BFormGroup, BForm, BFormSelect, BFormText, BPagination } from "bootstrap-vue";
import { ref, reactive, onMounted, watch, computed, toRefs } from "@vue/composition-api";
import {useUser} from './useUser';
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components:{
    BButton,
    BTable,
    BFormGroup, 
    BFormInput,
    BForm,
    BFormSelect,
    BFormText,
    BPagination,
    ToastificationContent
  },
  setup() {
     let fields = reactive( [
      { key: 'userName', label: 'userName', },
      { key: 'fullName', label: 'full name' },
      { key: 'mobile', label: 'mobile' },
      { key: 'email', label: 'email' },
      { key: 'role', label: 'role' },
      { key: 'action', label: 'action' },
     ]);
    let items = ref([]);
    let paging = reactive({
      page: 1,
      pageSize: 10,
      totalPage: 0,
      totalItem: 0,
    });
    let inputVal = reactive({
      id: 0,
      userName: "",
      fullName:"",
      mobile:"",
      email:"",
      password:"",
      newPassWord:""
    })
    let typeModal = reactive({
      add: false,
      edit: false,
      view: false
    })
    let myModal = ref();
    let deleteModal = ref()
    let roleSearch = reactive({
      selected: null,
      options: [ { value: null, text: 'เลือก' }, { value: "admin", text: 'admin' }, { value: "normalOfficer", text: 'normalOfficer' } ]
    })
    let inputDeleteVal = reactive({
      password: "",
    })

    const toast = useToast();
    const { findAllUser, createUser, updateUser, deleteOneUser } = useUser()

   onMounted(async () => {
      await fetchAllUser();
      // await fetchOptionStudent();
    });

    async function fetchAllUser(){
        await findAllUser({
        page: paging.page,
        pageSize: paging.pageSize,
      })
        .then((response) => {
          let { datas, pagination } = response.data;
          paging.page = pagination.currentPage;
          paging.pageSize = pagination.pageSize;
          paging.totalPage = pagination.totalPages;
          paging.totalItem = pagination.totalItems;
          items.value = datas;

        })
        .catch((err) => {
          const { config, response } = err;
          toast({ component: ToastificationContent, props: { title: response.data.message, icon: "XCircleIcon", variant: "danger", }});
        });
    }

    async function onSubmit(event) {
      event.preventDefault()
      if(typeModal.add){
        await createData()
      }else if(typeModal.edit){
        await updateData()
      }
    }


    async function editAction(data){
      typeModal.add = false;
      typeModal.edit = true;
      typeModal.view = false;

      console.log("data ",data);

      inputVal.id = data.id;
      inputVal.userName = data.userName
      // inputVal.password = data.password
      inputVal.email = data.email
      inputVal.mobile = data.mobile
      inputVal.fullName = data.fullName
      roleSearch.selected = data.role

      showModal()
    }

    function showModal() {
      myModal.value.show()
    }

   function onCloseMyModal(){
    typeModal.view = false;
    typeModal.add = false;
    typeModal.edit = false
    clearInput()
    myModal.value.hide()
   }
   

   function clearInput(){
    inputVal.id = 0;
    inputVal.userName = ""
    inputVal.password = ""
    inputVal.newPassWord = ""
    inputVal.email = ""
    inputVal.mobile = ""
    inputVal.fullName = ""
    roleSearch.selected = null
   }

  function addAction(){
     typeModal.add = true;
     typeModal.edit = false;
     typeModal.view = false;
     showModal()
   }



   async function createData(){
      await createUser({
        userName: inputVal.userName,
        password: inputVal.password,
        email: inputVal.email,
        fullName: inputVal.fullName,
        mobile: inputVal.mobile,
        role: roleSearch.selected
      }).then(async (response) =>{
        if (response.status == 200) {
           toast({
            component: ToastificationContent,
            props: { title: "Successfully create user.", icon: "CheckCircleIcon", variant: "success",},
          });

          paging.page = 1
          await fetchAllUser()
          onCloseMyModal()
        }
         
      }).catch((err) => {
      const { config, response } = err;
      toast({ component: ToastificationContent,
        props: { title: response.data.message,  icon: "XCircleIcon", variant: "danger"},
      });
     })
    }

    async function updateData(){
      await updateUser({
        id: inputVal.id,
        password: inputVal.newPassWord,
        email: inputVal.email,
        fullName: inputVal.fullName,
        mobile: inputVal.mobile,
        role: roleSearch.selected
      }).then(async (response) =>{
        if (response.status == 200) {
          toast({ component: ToastificationContent,  props: { title: "Successfully update user.", icon: "CheckCircleIcon", variant: "success"}});
          paging.page = 1
          await fetchAllUser()
          onCloseMyModal()
        }
         
      }).catch((err) => {
      const { config, response } = err;
      toast({ component: ToastificationContent,
        props: { title: response.data.message,  icon: "XCircleIcon", variant: "danger"},
      });
     })
    }

    function generatePassword() {
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_+=[]{}";
      let password = "";
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }
      if(typeModal.add) inputVal.password = password
      if(typeModal.edit) inputVal.newPassWord = password

    }

    function copyPassword(){
      let clipboardToCopy = document.querySelector('#testing-code')
      clipboardToCopy.setAttribute('type', 'text')   
      clipboardToCopy.select()
      document.execCommand('copy');
    }

   function onCloseDeleteModal(){
    clearInputDeleteModal()
    deleteModal.value.hide()
   }

   function clearInputDeleteModal(){
    inputDeleteVal.password = ""
   }

   function deleteRow(id){
     inputVal.id = id
     deleteModal.value.show()
   }

   function handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    }

   async function onSubmitDelete(event){
    event.preventDefault()
      await deleteOneUser({
        id: inputVal.id,
        password: inputDeleteVal.password,
      }).then(async (response) => {
          if (response.status == 200) {
            toast({  component: ToastificationContent,
              props: { title: "Successfully delete user.", icon: "CheckCircleIcon", variant: "success",},
            });
            paging.page = 1
            await fetchAllUser()
            onCloseDeleteModal()
          }
        })
        .catch((err) => {
          const { config, response } = err;
          toast({ component: ToastificationContent,
            props: { title: response.data.message, icon: "XCircleIcon", variant: "danger", },
          });
        });
   }

    return{
      //value
      fields, items, inputVal, typeModal, myModal, deleteModal, roleSearch, generatePassword,
      inputDeleteVal,

      //method
      onSubmit, editAction, onCloseMyModal, addAction, copyPassword, onCloseDeleteModal, deleteRow, handleKeyDown, 
      onSubmitDelete
    }
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  justify-content: flex-end;
}

.table{
  margin: 15px 0 0 0;
}

.row-mt{
  margin-top: 15px;
}

.body-confirmQuestionModal{
  background: black;
  color: white;
}

.copy{
  outline: 0;
  border: none;
  cursor: pointer;
  font-weight: 600;
  border-radius: 4px;
  font-size: 13px;
  height: 40px;
  width: 50px;
  background-color: #0000000d;
  color: #0e0e10;
  // padding: 0 10px;
  &:hover {
      background-color: #0000001a;
  }
                
}

</style>
import store from '@/store'
import { useRouter } from '@core/utils/utils'

export const useUser = () => {
  // eslint-disable-next-line arrow-body-style
  // const checkStudent = studentCode => {
  //   return store.dispatch('manage-student/checkStudent', { studentCode })
  // }
 const findAllUser = (...args) => store.dispatch('user/findAll', ...args)
 const createUser = (...args) => store.dispatch('user/create', ...args)
 const updateUser = (...args) => store.dispatch('user/update', ...args)
 const deleteOneUser = (...args) => store.dispatch('user/deleteOne', ...args)
  

  return {
    findAllUser,
    createUser,
    updateUser,
    deleteOneUser
  }
}
